<template>
  <div>
    <thux-table
      :instance="instance"
      :instance-name="instanceName"
      :order-by-dict="orderByDict"
      :goBack="goBack"
      :list="list"
      :fieldName="fieldName"
      :fields="fields"
      :fields-column="fieldsColumn"
      :select-all="selectAll"
      :selected-list="selectedList"
      :get-local-selected-all="getLocalSelectedAll"
      :local-selected-list="localSelectedList"
      @check-all="checkAll"
      @check-none="checkNone"
      @set-selected-row="setSelectedRow"
      :show-checks="showChecks"
      :showDetailButton="showDetailButton"
      @change-status="changeInstanceStatus"
      @sort-changed="orderTable"
      @remove-order-by="removeOrderFromTable"
      @open-edit-form="$emit('open-edit-form', $event)"
      @open-detail-form="$emit('open-detail-form', $event)"
    >
      <template slot="group_names" slot-scope="row">
        <div v-if="row.item.group_names">
          <span
            v-for="group in row.item.group_names.split(',')"
            :key="group"
            class="tag"
          >
            {{ group }}
          </span>
        </div>
      </template>
      <template slot="other-buttons-actions-bottom" slot-scope="row">
        <b-btn
          v-if="showDocumentsButton"
          variant="outline-primary"
          v-has-perms="showOrgDocsRolePerm"
          :title="'Show documents filtered by organization' | translate"
          :to="getDocumentsRouter(row.item.id, row.item.name)"
        >
          <i class="far fa-file-alt"></i>
        </b-btn>
        <b-btn
          v-if="showDownloadDocumentsButton"
          variant="outline-primary"
          v-has-perms="downloadOrgDocsRolePerm"
          :title="'Download organization documents' | translate"
          @click="openDownloadDocumentsModal(row.item)"
        >
          <i class="fas fa-cloud-download-alt"></i>
        </b-btn>
        <b-btn
          v-if="showPrivacyButton"
          variant="outline-primary"
          v-has-perms="privacyRolePerm"
          :title="'Privacy' | translate"
          @click="getPrivacyDocument(row.item)"
        >
          <i class="fas fa-user-secret"></i>
        </b-btn>
        <b-btn
          v-if="showSummaryButton"
          variant="outline-primary"
          v-has-perms="summaryExcelRolePerm"
          :title="'Summary Excel' | translate"
          @click="getSummaryDocument(row.item)"
        >
          <i class="fas fa-file-excel"></i>
        </b-btn>
        <b-btn
          v-if="showCompanyRegistryButton"
          variant="outline-primary"
          v-has-perms="downloadCompanyRegistryRolePerm"
          :title="'Download Company Registry' | translate"
          @click="openCompanyRegistryModal(row.item)"
        >
          <i class="fas fa-file-download"></i>
        </b-btn>
        <b-btn
          v-if="showDownloadNominationsButton"
          variant="outline-primary"
          v-has-perms="downloadNominationsRolePerm"
          :title="'Download Nominations' | translate"
          @click="openDownloadNominationsModal(row.item)"
        >
          <i class="fas fa-award"></i>
        </b-btn>
        <b-btn
          v-if="showAgreementsButton"
          variant="outline-primary"
          v-has-perms="agreementRolePerm"
          :title="'Agreement' | translate"
          :to="getAgreementRouter(row.item.id, row.item.name)"
        >
          <i class="fas fa-handshake"></i>
        </b-btn>
        <b-btn
          v-if="showOrganizationInspectionButton"
          variant="outline-primary"
          v-has-perms="inpesctionsRolePerm"
          :title="'Show inspections filtered by Organization' | translate"
          :to="getInspectionRouter(row.item.id, row.item.name)"
        >
          <i class="fas fa-wrench"></i>
        </b-btn>
      </template>
    </thux-table>
    <company-registry-modal
      v-if="showCompanyRegistryModal && orgId"
      :orgId="orgId"
      @close-modal="closeCompanyRegistryModal()"
      @download-company-registry="downloadRegistry($event)"
    />
    <download-documents-modal
      v-if="showDownloadDocumentsModal"
      :orgId="orgId"
      :orgName="orgName"
      @close-modal="closeDownloadDocumentsModal()"
    />
    <download-nominations-modal
      v-if="showDownloadNominationsModal"
      :orgId="orgId"
      :orgName="orgName"
      @close-modal="closeDownloadNominationsModal()"
    />
  </div>
</template>

<script>
import ThuxTableMixin from '@/components/thux-table/ThuxTableMixin'
import ThuxTableChecksMixin from '@/components/thux-table/thux-table-checks/ThuxTableChecksMixin'
import { mapActions } from 'vuex'
import { TYPES as ORG_TYPES } from '../../../organization/store'
import CompanyRegistryModal from '../organization-modal/CompanyRegistryModal'
import DownloadDocumentsModal from '../organization-modal/DownloadDocumentsModal'
import DownloadNominationsModal from '../organization-modal/DownloadNominationsModal'

export default {
  name: 'OrganizationTableMixin',
  mixins: [ThuxTableMixin, ThuxTableChecksMixin],
  components: {
    CompanyRegistryModal,
    DownloadDocumentsModal,
    DownloadNominationsModal
  },
  data () {
    return {
      showDocumentsButton: false,
      showDownloadDocumentsButton: false,
      showDownloadDocumentsModal: false,
      showSummaryButton: true,
      showCompanyRegistryButton: false,
      showCompanyRegistryModal: false,
      showAgreementsButton: false,
      showPrivacyButton: true,
      showDownloadNominationsButton: true,
      showOrganizationInspectionButton: true,
      showDownloadNominationsModal: false,
      orgId: undefined,
      orgName: undefined,
      fields: [
        {
          key: 'checks',
          label: this.$t('Checks')
        },
        {
          key: 'status',
          label: this.$t('Status'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'status'
        },
        {
          key: 'id',
          label: this.$t('Code'),
          type: 'number',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'id'
        },
        {
          key: 'name',
          label: this.$t('Business Name'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'name'
        },
        {
          key: 'vat_number',
          label: this.$t('VAT number'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'vat_number'
        },
        {
          key: 'ssn',
          label: this.$t('SSN'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'ssn'
        },
        {
          key: 'pec',
          label: this.$t('PEC'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'pec'
        },
        {
          key: 'sdi_code',
          label: this.$t('SDI / CUU'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'sdi_code'
        },
        {
          key: 'group_names',
          label: this.$t('Categories'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          custom: true
        },
        {
          key: 'action',
          label: this.$t('Actions'),
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        }
      ],
      fieldsColumn: ['status', 'code', 'name', 'vat_number', 'ssn', 'pec', 'sdi_code'],
      // Permissions
      showOrgDocsRolePerm: ['document_uploaddocument_organization_documents_list'],
      downloadOrgDocsRolePerm: ['document_uploaddocument_generate_documents_zip_job'],
      privacyRolePerm: ['organization_customerorganization_print_privacy'],
      summaryExcelRolePerm: ['organization_organization_download_summary'],
      downloadCompanyRegistryRolePerm: ['organization_organization_download_company_registry'],
      downloadNominationsRolePerm: ['organization_organizationcontactsecurityrole_generate_download_nomination_job'],
      agreementRolePerm: ['agreement_agreementdocument_valid_agreement_documents'],
      inpesctionsRolePerm: ['organization_organizationinspection_list']
    }
  },
  computed: {
    showChecks () {
      return true
    },
    showDetailButton () {
      return true
    }
  },
  methods: {
    ...mapActions({
      downloadSummary: ORG_TYPES.GENERIC.organization.organization.DETAIL.ACTIONS.download,
      downloadCompanyRegistry: ORG_TYPES.GENERIC.organization.organization.DETAIL.ACTIONS.downloadCompanyRegistry
    }),
    getDocumentsRouter (id, name) {
      return {}
    },
    getAgreementRouter (id, name) {
      return {
      }
    },
    getInspectionRouter (id, name) {
      return {
      }
    },
    getPrivacyDocument (item) {
      const fileName = `${this.$t('Privacy')}_${item.name.replace(/ /g, '')}.pdf`
      this.download({ id: item.id, action: 'print-privacy', simpleFileName: fileName })
    },
    getSummaryDocument (item) {
      const fileName = `${this.$t('Riepilogo')}_${item.name.replace(/ /g, '')}.xlsx`
      this.downloadSummary({ id: item.id, action: 'download-summary', simpleFileName: fileName })
    },
    openCompanyRegistryModal (item) {
      this.$set(this, 'showCompanyRegistryModal', true)
      this.$set(this, 'showDownloadDocumentsModal', false)
      this.$set(this, 'showDownloadNominationsModal', false)
      this.$set(this, 'orgId', item.id)
    },
    closeCompanyRegistryModal () {
      this.$set(this, 'showCompanyRegistryModal', false)
      this.$set(this, 'showDownloadNominationsModal', false)
      this.$set(this, 'orgId', undefined)
    },
    downloadRegistry (event) {
      this.downloadCompanyRegistry({ id: event.orgId, staffIds: event.selectedIdList })
      this.$set(this, 'showCompanyRegistryModal', false)
      this.$set(this, 'orgId', undefined)
    },
    openDownloadDocumentsModal (item) {
      this.$set(this, 'showDownloadDocumentsModal', true)
      this.$set(this, 'showCompanyRegistryModal', false)
      this.$set(this, 'showDownloadNominationsModal', false)
      this.$set(this, 'orgId', item.id)
      this.$set(this, 'orgName', item.name)
    },
    closeDownloadDocumentsModal (item) {
      this.$set(this, 'showDownloadDocumentsModal', false)
      this.$set(this, 'showCompanyRegistryModal', false)
      this.$set(this, 'showDownloadNominationsModal', false)
      this.$set(this, 'orgId', undefined)
      this.$set(this, 'orgName', undefined)
    },
    openDownloadNominationsModal (item) {
      this.$set(this, 'showCompanyRegistryModal', false)
      this.$set(this, 'showDownloadDocumentsModal', false)
      this.$set(this, 'showDownloadNominationsModal', true)
      this.$set(this, 'orgId', item.id)
      this.$set(this, 'orgName', item.name)
    },
    closeDownloadNominationsModal (item) {
      this.$set(this, 'showDownloadDocumentsModal', false)
      this.$set(this, 'showCompanyRegistryModal', false)
      this.$set(this, 'showDownloadNominationsModal', false)
      this.$set(this, 'orgId', undefined)
      this.$set(this, 'orgName', undefined)
    }
  }
}
</script>
