<template>
  <!-- Add/Edit Form -->
  <b-modal
    id="modalCompanyRegistry"
    ref="modalCompanyRegistry"
    :no-enforce-focus="true"
    :no-close-on-backdrop = 'true'
    size='lg'
    @hidden="modalCompanyRegistryOnHidden"
  >
    <div slot="modal-title">
      {{ modalTitle | translate }}
    </div>
    <div class="mb-3">
      {{message}}
    </div>
    <thux-list-component
      :fields="getFields"
      :pagination="getPaginationFields"
      @select-per-page="changePerPage"
      @change-page="changePage"
      :action-select="getActionSelectFields"
      @select-action="setAction"
      @select-or-deselect-all-queryset="selectOrDeselectAllQueryset"
      @do-action="doAction($event, fieldName)"
      :advanced-search="getAdvancedSearchFields"
      @show-advanced-search="openAdvancedSearch"
    >
      <template slot="body">
        <component-table
          :list="list.results"
          :goBack="goBack"
          :params="tableParams"
        />
      </template>
    </thux-list-component>
    <div slot="modal-footer">
      <b-btn class="mr-3" variant="default" @click.prevent="closeAndGoToList()">
        {{ 'Cancel' | translate }}
      </b-btn>
      <b-btn
        variant="primary"
        @click.prevent="closeAndDownloadDocument()"
      >
        {{ 'OK' | translate }}
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import ThuxListMixin from '@/components/thux-list/ThuxListMixin'
import CompanyRegistryContactTable from './CompanyRegistryContactTable'

import { TYPES as ORG_CNT_TYPES } from '../../../organization-contact/store'

export default {
  name: 'CompanyRegistryModal',
  mixins: [ThuxListMixin],
  props: ['orgId'],
  data () {
    return {
      selectedItemId: undefined,
      selectedOrgTypes: [],
      showCommonFilters: false
    }
  },
  components: {
    'component-table': CompanyRegistryContactTable
  },
  created () { },
  mounted () {
    this.openModalCompanyRegistry()
  },
  computed: {
    ...mapGetters({
      ...ORG_CNT_TYPES.GENERIC.organization.organizationcontact.LIST.GETTERS,
      selectedList: ORG_CNT_TYPES.GENERIC.organization.organizationcontact.LIST.GETTERS.selectedList
    }),
    modalTitle () {
      return this.$t('Select company staff')
    },
    message () {
      return this.$t('Select the personnel to be included in the company data sheet. If no name is selected, all staff will be included in the document.')
    }
  },
  methods: {
    ...mapActions({
      ...ORG_CNT_TYPES.GENERIC.organization.organizationcontact.LIST.ACTIONS
    }),
    openModalCompanyRegistry () {
      this.$refs.modalCompanyRegistry.show()
    },
    modalCompanyRegistryOnHidden (evt) {
      this.$emit('close-modal')
    },
    closeAndGoToList (evt) {
      this.$emit('close-modal')
    },
    getComponentList () {
      const filters = {
        filter__organization__id: this.orgId,
        filter__status: 1,
        filter__contact__status: 1
      }
      this.setFilters(filters)
    },
    closeAndDownloadDocument () {
      const selectedIdList = this.selectedList.map(a => a.id)
      console.log(this.selectedList)
      console.log(selectedIdList)
      this.$emit('download-company-registry', { orgId: this.orgId, selectedIdList: selectedIdList })
    }
  }
}
</script>
