<template>
  <!-- Add/Edit Form -->
  <b-modal
    id="modalDownloadNominations"
    ref="modalDownloadNominations"
    :no-enforce-focus="true"
    :no-close-on-backdrop = 'true'
    size='lg'
    @hidden="modalDownloadNominationsOnHidden"
  >
    <div slot="modal-title">
      {{ modalTitle | translate }}
    </div>
    <div class="mb-3">
      <p>
        {{ 'Select nominations you want to download for organization' | translate }} {{orgName}}
      </p>
      <p>
        {{ 'Once the download has been requested, the system will generate a .zip file containing the required documents.' | translate }}
      </p>
      <p>
        {{ 'When the required file is ready, the download link will be displayed in the in bar by pressing the icon' | translate }} <i class="fas fa-cloud-download-alt"></i>
      </p>
    </div>
    <b-form>
      <b-form-row>
        <form-thux-horizontal-checkbox
          v-for="(code, idx) in CONTACT_SECURITY_ROLES_CODES" :key='idx'
          :validator="$v.printForm[code]"
          :label-form="`${$t('Nomination')} ${code}`"
          class-form="col-lg-6 mb-2"
          label-cols-lg="6"
          label-cols-sm="6"
          :value="printForm[code]"
          @change="$set(printForm, code, $event)"
        />
      </b-form-row>
    </b-form>
    <div slot="modal-footer">
      <b-btn class="mr-3" variant="default" @click.prevent="closeAndGoToList()">
        {{ 'Cancel' | translate }}
      </b-btn>
      <b-btn
        variant="primary"
        @click.prevent="closeAndDownloadDocument()"
      >
        {{ 'OK' | translate }}
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex'
import slugify from 'slugify'

import FormThuxHorizontalCheckbox from '@/components/form-thux-horizontal-components/FormThuxHorizontalCheckbox'

import { TYPES as ORG_CNT_SECURITY_ROLES_TYPE } from '../../../organization-contact-security-role/store'
import { CONTACT_SECURITY_ROLES_CODES } from '@/const'

export default {
  name: 'DownloadNominationsModal',
  props: ['orgId', 'orgName'],
  data () {
    return {
      printForm: {},
      CONTACT_SECURITY_ROLES_CODES
    }
  },
  components: {
    'form-thux-horizontal-checkbox': FormThuxHorizontalCheckbox
  },
  created () { },
  mounted () {
    this.openModalDownloadNominations()
  },
  computed: {
    modalTitle () {
      return this.$t('Select nominations to download')
    },
    message () {
      return this.$t('Select the personnel whose required documents you want to download. If no name is selected, the documents of all staff will be downloaded.')
    }
  },
  methods: {
    ...mapActions({
      generateNominationZipJob: ORG_CNT_SECURITY_ROLES_TYPE.GENERIC.organization.organizationcontactsecurityrole.LIST.ACTIONS.generateNominationZipJob
    }),
    openModalDownloadNominations () {
      this.$refs.modalDownloadNominations.show()
    },
    modalDownloadNominationsOnHidden (evt) {
      this.$emit('close-modal')
    },
    closeAndGoToList (evt) {
      this.$emit('close-modal')
    },
    getComponentList () { },
    closeAndDownloadDocument () {
      const formData = {}
      formData.role_code_list = []
      formData.file_name = `${slugify(this.orgName)}_nomine.zip`
      formData.organization = this.orgId
      Object.keys(this.printForm).forEach(key => {
        if (this.printForm[key]) {
          formData.role_code_list.push(key)
        }
      })
      this.generateNominationZipJob(formData).then(
        () => {
          this.closeAndGoToList()
        })
    }
  },
  validations () {
    const form = {}
    this.CONTACT_SECURITY_ROLES_CODES.forEach(el => {
      form[el] = {}
    })
    return {
      printForm: form
    }
  }
}
</script>
