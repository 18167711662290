<template>
  <thux-table
    :instance="instance"
    :instance-name="instanceName"
    :order-by-dict="orderByDict"
    :goBack="goBack"
    :list="list"
    :fieldName="fieldName"
    :fields="fields"
    :fields-column="fieldsColumn"
    :select-all="selectAll"
    :selected-list="selectedList"
    :get-local-selected-all="getLocalSelectedAll"
    :local-selected-list="localSelectedList"
    @check-all="checkAll('id')"
    @check-none="checkNone('id')"
    @set-selected-row="setSelectedRow($event, 'id')"
    :show-checks="showChecks"
    :showDetailButton="showDetailButton"
    @change-status="changeInstanceStatus"
    @sort-changed="orderTable"
    @remove-order-by="removeOrderFromTable"
    @open-detail-form="$emit('open-detail-form', $event)"
  >
  </thux-table>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as ORG_CNT_TYPES } from '../../../organization-contact/store'

import ThuxTableMixin from '@/components/thux-table/ThuxTableMixin'
import ThuxTableChecksMixin from '@/components/thux-table/thux-table-checks/ThuxTableChecksMixin'

export default {
  name: 'CompanyRegistryContactTable',
  mixins: [ThuxTableMixin, ThuxTableChecksMixin],
  data () {
    return {
      fields: [
        {
          key: 'checks',
          label: this.$t('')
        },
        {
          key: 'contact__last_name',
          label: this.$t('Last name'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'contact_last_name'
        },
        {
          key: 'contact__first_name',
          label: this.$t('First name'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'contact_first_name'
        },
        {
          key: 'contact__ssn',
          label: this.$t('SSN'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'contact_ssn'
        }
      ],
      fieldsColumn: ['contact__last_name', 'contact__first_name', 'contact__ssn'],
      instance: undefined
    }
  },
  computed: {
    ...mapGetters({
      selectAll: ORG_CNT_TYPES.GENERIC.organization.organizationcontact.LIST.GETTERS.selectAll,
      selectedList: ORG_CNT_TYPES.GENERIC.organization.organizationcontact.LIST.GETTERS.selectedList
    }),
    showChecks () {
      return true
    },
    showDetailButton () {
      return true
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList:
        ORG_CNT_TYPES.GENERIC.organization.organizationcontact.LIST.MUTATIONS.setSelectedList,
      setSelectAll: ORG_CNT_TYPES.GENERIC.organization.organizationcontact.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...ORG_CNT_TYPES.GENERIC.organization.organizationcontact.LIST.ACTIONS
    })
  }
}
</script>
