
<script>
import { hasPerm } from '@/components/PermissionHelper'
import ThuxListMixin from '@/components/thux-list/ThuxListMixin'

export default {
  name: 'OrganizationListMixin',
  mixins: [ThuxListMixin],
  data () {
    return {
      searchFields: [
        { key: 'filter__name__icontains', placeholder: this.$t('Business Name'), type: 'text', col: 6 },
        { key: 'filter__ssn_code__icontains', placeholder: this.$t('SSN'), type: 'text', col: 6 },
        { key: 'filter__vat_number__icontains', placeholder: this.$t('VAT number'), type: 'text', col: 6 },
        // { key: 'filter__sdi__icontains', placeholder: this.$t('SDI'), type: 'text', col: 6 },
        { key: 'filter__organization_groups__name__icontains', placeholder: this.$t('Category'), type: 'text', col: 6 }
      ],
      editComponent: false,
      detailComponent: false
    }
  },
  computed: {
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('organization_organization_create')
    }
  }
}
</script>
